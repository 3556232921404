@import "../../../../../../variables.scss";
body #root {
  .students-report{
    .students-report-header{
      display: flex;
      justify-content: space-between;
    }
  }
  .students-list.bubble {
    align-items: stretch;
    margin-bottom: 3em;
    .item{
      flex-grow: 1;
      margin-bottom: 1.6em;

      .personal-data{
        display: flex;
        flex-direction: row;
        font-weight: 600;
        span{
          display: flex;
          align-items: center;
          flex-direction: row;
          margin-right: 0.6em;
          svg{
            color: $primaryLight;
          }
        }
      }

      .grade{
        display: flex;
        flex-direction: row;
        align-items: center;
        font-weight: 700;
        font-size: 0.8em;
        svg{
          color: $gold;
        }
      }
    } 
  }

  .linear-progress-bar{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-self: stretch;
    justify-content: center;
    align-items: center;
    .value{
      font-size: 0.8em;
      color: rgba(0,0,0,0.5);
      line-height: 0;
      height: 0.8em;
    }
    .progress{
      width: 100%;
      height: 0.5em;
    }
  }

  .course-progress{
    .title{
      font-size: 0.8em;
      font-weight: 700;
      color: $primary;
    }
    
    .data-grid-container{
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      min-height: 200px;
      align-items: stretch;
      justify-content: stretch;
    }
  }

  .reports {
    display: flex;
    // padding: 30px;
    flex-direction: column;
    justify-content: stretch;
    margin-bottom: 3em;


    .button-container{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      align-self: stretch;
      margin-top: 1em;
      border-top: 2px dotted rgba(0,0,0,0.1);
      padding-top: 1em;
    }

    .status-item{
      h5{        
        color: $primary;
        margin-bottom: 1em;        
      }
    }

    
    //background-color: cornflowerblue;
    
    .student-bubble {
      margin-bottom: 1em;
      margin-top: 1em;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;

      .students-info {
        display: flex;
        justify-content: space-around;
        margin-top: 15px;
      }
    }

    .program-bubble {
      margin-top: 1em;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;

      .programs-list {
        display: flex;
        width: 100%;
        min-height: 30vh;
        flex-direction: column;
        padding: 0 2em;
      }
    }
  }

  .program-list{
    align-items: stretch;
    margin-bottom: 3em;

    .program-report-item{
      margin: 2em 0 0 ;
    }
  }
}
