.image-selector{
  display: flex;
  flex-grow: 1;
  align-items: stretch;
  justify-content: stretch;    
  cursor: pointer;
  position: relative;
  .display{
    display: flex;
    flex-grow: 1;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    padding: 0 0 56%;

    border: 1px solid rgba(0,0,0,0.3);
    border-radius: 1em;
    overflow: hidden;
    background-color: rgba(0,0,0,0.1);
  }

  &:hover{
    .image-selector-close{
      background: red;
      color: white;
    }  
  }
  .image-selector-close{
    background: rgba(0,0,0,0.1);
    transition: 0.5s ease-out background-color,  0.2s ease-out color ;
    position: absolute;
    left: 0.2em;
    top: 0.2em;

  }
}