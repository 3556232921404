@import "../../../variables.scss";

.extended-list{
  position: relative;
  .clear-list{
    font-size: 1em;
    padding: 0;
    margin: 0;
    position: relative;    
    min-width: inherit;
    width: 2em;
    background-color: gray;    
    color: white;
    transition: 0.5s all ;
    &:hover{
      background-color: $logoColor;      
    }
  }
}
.select-list{

  .actions {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: stretch;
  }

  ul, .MuiList-root{
  padding: 1em;
  border-radius: 1em;
  border: 1px solid rgba(0,0,0,0.2);
  background: $modalBgDark;

  .select-list-item{
    padding: 0.2em 0.5em;
    background: $modalBgLight;
    border-radius: 0.5em;
    margin: 0.5em 0;
    transition: 0.2s ease-out all;
    cursor: pointer;

    span:first-child{
      color: $logoColor50p;
    }

      &:hover{
        // background: rgba(255,255,255,1);
        background: $logoColor;
        color: rgba(255,255,255,0.8);
        span:first-child{
          color: white;
        }
      }
      

    }
  }
}