// @import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700;900&display=swap");
@import "./variables.scss";

html,
body {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

body {
  flex-grow: 1;
  background-color: $bgBody;
  font-family: "Noto Sans", sans-serif;
  font-weight: 400;

  #root {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex-grow: 1;

    @for $var from 1 to 6 {
      h#{$var} {
        font-size: #{2 - ($var - 1) * 0.2}em;
        font-weight: 300;
        // margin-top: #{1.6 - ($var - 1) * 0.2 }em;
        margin-top: #{$var * 0.5}em;
        margin-bottom: #{$var * 0.1}em;
        // margin-bottom: #{1.4 - ($var - 1) * 0.2 }em;
      }
    }

    .bubble {
      background: $bgLight;
      border-radius: 10px;
      display: flex;
      padding: 1em;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      overflow: hidden;

      &.row {
        flex-direction: row;
      }

      &.stretch {
        align-items: stretch;
        justify-content: stretch;
      }

      h3 {
        margin: 0 0 1em;
        color: $primary;
      }
    }

    .bold {
      font-weight: 600;
    }

    .navbar {
      // background: #5c7372;
      height: $headerHeight;
      padding: 0 2em;
      color: white;
      .menu-button {
        cursor: pointer;
      }
    }

    .main {
      flex-grow: 1;
      display: flex;
      justify-content: stretch;
      transition: 255ms padding-left;
      padding-top: #{$headerHeight};
      padding-bottom: #{$footerHeight + $mainTopMargin};
      &.drawer-open {
        padding-left: $sidebarWidth;
        @media screen and (max-width: $bp_mobileLandscape) {
          padding-left: inherit;
        }
      }

      // Make first container within main 100% width, but leave the margins on.
      & > .MuiContainer-root {
        max-width: inherit;
      }
    }

    .footer {
      display: flex;
      flex-grow: 0;
      height: $footerHeight;
      align-items: center;
      width: 100%;
      justify-content: center;
      color: white;
      z-index: 1;
      // background: #7ea09f;
    }

    // ALERTS
    // $alerts: Success, Error, Info, Warning;
    .MuiAlert-filledWarning {
      background-color: $warningColor;
    }
    .MuiAlert-filledSuccess {
      background-color: $successColor;
    }
    .MuiAlert-filledError {
      background-color: $errorColor;
    }
    .MuiAlert-filledInfo {
      // background-color: $warningColor;
    }
  }

  .spinner-container {
    position: relative;
  }

  .skeleton {
    padding: 1em;
    display: flex;
    flex-grow: 1;
    min-height: 3em;
    background-color: rgba(0, 0, 0, 0.06);
    border-radius: $borderRadius;
    margin: 1em 0;
  }

  $colors: white, red, green, yellow, orange, blue, cyan, teal;
  @each $var in $colors {
    .bg-#{$var} {
      background-color: $var;
      &:hover {
        background-color: darken($var, 5%);
      }
    }
    .color-#{$var} {
      color: $var;
    }
  }

  .tab-panel {
    // transition: 0.4s ease-out height, 0.4s ease-out padding;
    // height: inherit;

    &.hide {
      // height: 0!important;
      // padding: 0!important;
      // overflow: hidden;
      display: none !important;
    }
  }
}

$flexItemsMethods: baseline, flex-start, flex-end, stretch, center;
$flexContentMethods: baseline, flex-start, flex-end, stretch, center,
  space-around, space-evenly, space-between;

.flex {
  display: flex;

  &.column {
    flex-direction: column;
  }
  &.row {
    flex-direction: row;
  }

  @each $var in $flexItemsMethods {
    &.align-items-#{$var} {
      align-items: $var;
    }
  }

  @each $var in $flexItemsMethods {
    &.align-self-#{$var} {
      align-self: $var;
    }
  }

  @each $var in $flexContentMethods {
    &.justify-content-#{$var} {
      justify-content: $var;
    }
  }
}

$widths: 10, 20, 30, 40, 50, 60, 70, 80, 90, 100;

@each $var in $widths {
  .w-#{$var}p {
    width: $var * 1%;
  }
}


.grow-0 {
  flex-grow: 0;
}

.grow-1 {
  flex-grow: 1;
}

.grow-2 {
  flex-grow: 1;
}

// style modal window

.modal-window{
  &.full-width{
    width: 100%;
  }
  .modal-content{
    min-height: 4em; // to at least show the spinner while opening
  }
}


// help tooltip content 
[role="tooltip"] { 

    .help-tip-content{
      // increase font size
      font-size: 1.4em;
      // make font thinner
      font-weight: 400;
      // update line height
      line-height: 1.2em;
      // add small padding
      padding: 0.3em;
    }
  
}

// customize scrollbars throughout the app
::-webkit-scrollbar {
  background: whitesmoke;
  width:  8px;
}

::-webkit-scrollbar-thumb{
    width: 16px;
    background: #75757548;      
    border-radius: 8px;
    box-shadow: 0px 1px 2px rgba(0,0,0,0.5)
}