#root {

  h1{

    .right-side{
      button{
        margin: 0 0.4em;

        &:last-child{
          margin-right: 0;
        }
      }
      button.red-indicator::after {
        content:  "";
        width:  10px;
        height: 10px;
        background:  red;
        position: absolute;
        top: -4px;
        right: -5px;
        border-radius: 50%;
      }
    }
  } 

  .report-programs{
    .program-user-grades {
      .program-title{
        align-self: stretch;
        margin: 1em 0 0.5em;
        h2{
          padding: 0;
          margin: 0;
        }
      }
    }
    
  }
}