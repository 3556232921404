@import "../../../variables.scss";

.group-list{
    .buttons{
        display: flex;
        justify-content: space-between;
    }
}
.details-container {
    border-radius: $borderRadius;     
    background-color: $bgLight;   
    margin: 1em 0;
    width: 100%;
    .details-container {
        border-radius: $borderRadius;     
        background-color: $bgLight;   
        margin: 1em 0;
        width: 100%;
        .image-container {
            //background-color: wheat;
            display: flex;      
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .image {
              height: 20vh;
              width: auto;
              //border: 5px solid #636035;        
              border-radius: $borderRadius;
              box-shadow: 2px 2px 4px rgba(0,0,0,0.4);
            }
        } 
    }
}