@import "../../../variables.scss";

.sidebar {
  z-index: 1;
  position: relative;

  @media screen and (max-width: $bp_mobileLandscape) {
    position: absolute;
    // width: 100%;
    width: 0;
    height: 100%;
  }

  > div {
    width: $sidebarWidth;
    padding-top: $headerHeight;
    // top: $headerHeight;
    // height: calc(100% - #{$headerHeight + $footerHeight})    ;

    @media screen and (max-width: $bp_mobileLandscape) {
      width: 100%;
    }

    .drawer-menu-item {
      &.active {
        background-color: rgba(0, 0, 0, 0.1);
      }
    }
  }
}
