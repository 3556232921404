.filter-form{
  h1{
    font-size: 1em;
    // border-bottom: 1px solid;
    color: gray;
    font-weight: bold;
    margin: 0 0 0.5em;
    padding: 0;
  }
  ul[role="menu"]{
    margin: 1em;

    > li {
      padding: 0.5em;
      border: #ccc 1px solid;
      border-radius: 0.5em;
      margin: 0.2em 0 0.8em;
      transition: 1s all;

      &.has-errors {
        border-color: red;        
        > label {
          background-color: red;
          color: white;
        }

        span.error-message{
          color: red;
          font-size: 0.8em;
        }
      }

      label{
        transition: 1s all;
        background-color: #ccc;
        margin: -0.65em -0.65em 0.5em;
        padding: 0.65em;
        border-radius: 0.65em 0.65em 0 0 ;
        font-size: 0.8em;                
      }
    }
  }

  .MuiMenu-paper{

    width: 40vh;
    max-width: 500px;

    .MuiInput-underline:before{
      border-bottom: none; // remove underline nder chips
    }
    
    .chips-select{
      
      > .MuiSelect-select{
        // give air to chips
        padding: 0.4em;
        border-radius: 0.4em;
       


        > div {
          display: flex;
          flex-wrap: wrap;
          flex-grow: 1;
        }
      }
    }
  }

  .buttons{
    display: flex;
    justify-content: space-between;
  }
}
#chips-item-list{

  ul{
    li {
      padding-left: 3em;

      &.Mui-selected{
        &::before{
          position: absolute;
          left: 1em;          
          content: '\2713';
        }        
      }
      
    }
  }
}