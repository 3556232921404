@import "../../../variables.scss";

#root{
  .programs{
    --bg-light: white;
    margin-bottom: 3em;

    h1 {
      display: flex;
      justify-content: space-between;
      .buttons{
        display: inline-flex;
        align-items: center;
      }
      margin-bottom: 0.5em;
    }

    .course-list-wrapper{
      h3{
        font-size: 1.3em;
        font-weight: normal;
        margin: 0.2em 0 0em;
      }
      .custom-card-list-item{
        
      }
    }

  }
  .single-program{    
    .program-course-list{      
      counter-reset: clist-count;
      list-style: none;
      padding: 0;

      li{
        counter-increment: clist-count;
        position: relative;
        margin: 0 0 1em;
        list-style: none;        

        display: flex;
        align-items: center;
        background: rgba(255,255,255,0.2);
        padding: 0.5em 0.5em 0.5em 3em;
        border-radius: 0.5em;

        &:hover{
          background: rgba(255,255,255,0.4);
        }

        a{
          text-decoration: none;
          flex-grow: 1;
          color: black;

          div{
            font-size: 1.4em;
          }
          div:first-child{
            font-weight: 300;
            font-size: 1.3em;
            color: $primary;
            margin-right: 0.6em;
          }
        }

        &::before{          
          content: counter(clist-count);
          font-size: 1.2em;
          background: $primaryLighter;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          width: 2em;
          height: 2em; 
          left: 0.25em;
          top: 50%;  
          transform: translateY(-50%);       

          color: white;
        }
        
      }
    }
  }
}

.edit-program-modal{
  .modal-content{


    .select-list{
      

      ul, .MuiList-root {
        max-height: 15em;
        overflow-y: auto;
        .select-list-item{
          span{
            margin: 0 0.5em 0 0;
      
            &:first-child{
              font-weight: 600;
              // color: $logoColor50p;
            }
          }
        }
      }
    }

    .buttons{
      margin: 1em 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .tabs-container{
      margin: 0 0 0.5em;
      min-height: inherit; // decrease tabs height
      .MuiTab-root{
        padding: 0.2em 0.5em; // decrease tabs height
        min-height: inherit;
      }
    }
    .prompt{
      background: #64b2f13d;
      padding: 0.5em 1em;
      border-radius: 1em;
      color: #318bd2;
    }

    h3{
      margin: 0.5em 0 0; // reduce space around list headers
    }

    .form{
      .form-field{
        margin: 0.5em 0;
      }

      .fields-and-image{
        display: flex;
        flex-direction: row;
        @media screen and (max-width: $bp_mobileLandscape){
          // switch to vertical on small screens
          flex-direction: column;
        }
        
        .fields{
          flex-basis: 70%;
          @media screen and (max-width: $bp_mobileLandscape){
            // switch to vertical on small screens
            flex-basis: 100%;
          } 
        }

        .image-selector-container{
          flex-basis: 30%;
          display: flex;
          flex-direction: column;
          
          @media screen and (max-width: $bp_mobileLandscape){
            // switch to vertical on small screens
            flex-basis: 100%;
            min-height: 10em; // fix height on vertical layout
          } 

          .image-selector{
                        
            flex-direction: row;
            justify-content: center;
            @media screen and (max-width: $bp_mobileLandscape){
              flex-direction: column;
              align-items: center;
              justify-content: stretch;
              .display{
                min-width: 6em;
              }
            }

            .display{
              flex-grow: 0;
              // min-width: 6em;
              aspect-ratio: 1;
            }
          }

          .border{
            margin: 0 0.5em 0.5em; // no top margin cause there's the legend text
            border: 1px solid #acb6bf; // mocking the standaer MUI label border
            flex-grow: 1;
            border-radius: 0.2em;
            padding: 0.5em;
            display: flex; // this will allow the image selector to fill the entire space
            flex-direction: column;

            @media screen and (max-width: $bp_mobileLandscape){
              margin: 0.5em 0; // remove horizontal margin on vertical layout
            } 

            legend{
              font-size: 0.75em;
              // color: rgba(0, 0, 0, 0.7)
              color: $primaryLighter;
            }

            .display{
              padding: inherit; // this remove the padding from the image selector
            }
          }
        }
        
      }
    }
  }
  
}