@import "../../../../../variables.scss";
body{

  .filter-form.report-courses {
    .menu-items{
      .select-list{
        ul{
          max-height: 20em;
          overflow-y: auto;
          background: none;
          border: none;
          padding-left: 0;
          li{
            border-radius: 0;
            background: none;
            margin: 0.2em 0;
            

            > span {
              // make space for checkmark
              padding: 0.2em 0.2em 0.2em 2em;
              margin-left: -1em;
              color: #888;
              width: calc(100% - 1em); // fill width
              

              &.selected{
                background: #ccc;
                &::before{
                  content: '✓';
                  position: absolute;
                  left: 0.5em;
                }
              }
            }

            &:hover{
              // background-color: #ccc;
              // color: black;

              > span {
                background: #ddd;
                color: black;
              }
            }
          }
        }
      }
    }    
  }

  #root {
    .course-report{
      margin: 0 0 2em; // bottom page margin
      .course-report-item{
        align-items: stretch;
        padding-top: 1.6em;
        
        > span {
          font-size: 0.8em;
          color: rgba(0,0,0,0.5);
        }

        > h2 {
          padding: 0;
          margin: 0 0 0.2em;
        }

        .course-item-experiments{
          background: white;
          align-items: stretch;
        }

        .experiment {
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          margin: 0 0 2em;
           > h3{
            font-size: 1.3em;
            margin : 0 0 0.2em;
           }
           > span {
            font-size: 0.8em;
            color: rgba(0,0,0,0.6);
           }
          .chapter{
            h4{
              // chapter header
              text-align: center;
              padding: 0.2em 1em;
              background-color: $bgBody;
              font-size: 0.95em;
              color: white;
              margin: 0.8em 0;
            }

            .questions {
              $questionMargin: 2%;

              display: flex;
              flex-wrap: wrap;
              margin: 0 -$questionMargin; // compensate leftmost and rightmost items' margin

              // "no data found" message
              .no-data {
                margin: $questionMargin;
                color: rgba(255,0,0,0.6);
                font-size: 0.8em;
              }

              .question {
                
                font-size: 0.8em;
                margin: $questionMargin;
                
                
                max-width: calc(20% - #{$questionMargin * 2});
                
                align-self: stretch; // to extend to height of highest item in row
                justify-content: flex-start; 
                display: flex;
                flex-direction: column;

                img {
                  max-width: 100%;
                  margin: 0.5em 0;
                }

                table {
                  tr {
                    td{

                      border-bottom: 1px solid #ccc;

                      &:first-child{
                        // dirty trick to align icon and text/image vertically
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                      }
                    }
                  }
                }

                // responsive widths for the items
                @media screen and (max-width: $bp_desktopLarge) {
                  max-width: calc(25% - #{$questionMargin * 2});
                }
                @media screen and (max-width: $bp_tablet) {
                  max-width: calc(33% - #{$questionMargin * 2});
                }
                @media screen and (max-width: $bp_mobileLandscape) {
                  max-width: calc(50% - #{$questionMargin * 2});
                }
                @media screen and (max-width: $bp_mobile) {
                  max-width: 100%;
                }
              }              
            }
          }
        }
      }
    }
  }
}