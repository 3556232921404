@import "../../../variables.scss";

// this is 3.9em for H1 title, 4em for padding+margin of main container, 3.2em for breadcrumbs, 40px for header
      // if title drops to second line, this breaks ((
$containerHeight: calc(100vh - 7.9em - 3.2em - 40px);
$containerHeight2: calc(100vh - 7.9em - 3.2em - 40px - 2em);


#root {
  .course-main-container {
    // margin: 1em;
    // padding: 1em;
    flex-grow: 1;


    .paper{
      
      height: $containerHeight;

      .grid-container{
        flex-grow: 1;	
      }
    }

    h1{
      // increase bottom margin to compensate Grid container negative margins
      margin-bottom: 0.5em;
    }

    .vertical {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 2em;
      h4 {
        margin-top: 0em;
        color: $logoColor;
      }
    }

    .bottom-buttons {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin: 2em 0 2em;

      a[role="button"] {
        padding: 0.6em 1.2em;
        font-size: 1.15em;
        min-width: 10em;
        margin: 0 0.5em;
      }
    }

    .tester-tools {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      background: #00c0da2e;
      border-radius: 10px;
      &::before {
        position: absolute;
        content: "Testing tools";
        left: 0;
        top: -1.2em;
        font-size: 0.8em;
        color: #97d6e4;
        font-weight: 700;
      }

      button,
      a {
        margin: 0.4em 0.4em 0.4em 0;
      }
    }

    .parsed-html {
      img {
        max-width: 100%;
      }
    }
  }


  .course-main-container {
    .course-accordion-block {
      position: relative;

      // &.scrolled {
      //   .exp-list-container {
      //     // height: calc(100vh - $headerHeight);
      //     position: absolute;
      //     width: 100%;
      //   }
      // }
    }
  }
  .exp-list-container {
    //the accordion always in viewport
    // position: sticky;
    position: absolute;
    width: 100%;
    top: 0;

    // just looks nice in most cases.
    // Have to switch to flex grow to bottom of the page, both this and experiment content
    // max-height: 52vh;
    // height: calc(100vh - $headerHeight);
    overflow-y: auto;
    overflow-x: hidden;

    counter-reset: exp-counter;
    .accordion-list {
      flex-grow: 1;
      // flex-direction: column;

      .accordion-experiment-title {
        padding-left: 2em;
        display: flex;
        align-items: center;
        color: inherit;
        text-decoration: none;
        &::before {
          counter-increment: exp-counter;
          content: counter(exp-counter);
          background: gray;
          border-radius: 50%;
          width: 2em;
          height: 2em;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          left: 0.5em;
          color: white;
        }
      }
      .chapters {
        // make the accordion occupy the whole widths
        flex-grow: 1;
        a {
          &.active {
            background-color: rgba(0, 0, 0, 0.1);
          }
        }
      }

      // ul {
      //   li {
      //     display: flex;
      //     flex-grow: 1;
      //     a {
      //       text-decoration: none;
      //       color: inherit;
      //       display: flex;
      //       align-items: center;
      //       text-transform: capitalize;
      //       &:hover,
      //       &.active {
      //         color: black;
      //         background: gray;
      //       }
      //     }
      //   }
      // }
    }
  }
  .exp-content-container {
    //background-color: rgb(231, 231, 220);
    padding-left: 2em;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: $primary;
      &:first-child{
        // remove top margin if heading is first thin in document
        margin-top: 0;
      }
    }

    h4 {
      margin: 2em 0 0;
    }

    // limit height of content to fit in viewport
    max-height: $containerHeight2;
    overflow-y: auto;
  }

  .error-message {
    text-align: center;
    padding: 60px;
  }

}
