@import "../../variables.scss";

#root {

  .dashboard {
    h1 {
      font-size: 3em;
      margin: 0.2em 0 1.5em;
    }
    .main-icons {
      .main-icons-grid {
        display: flex;
        flex-direction: row;
        align-items: stretch;
      }
      .main-icons-item {
        // background: rgba(0, 0, 0, 0.1);
        // padding: 2em;
        // border-radius: 10px;
        // display: flex;
        // flex-direction: column;
        // align-items: center;
        justify-content: space-between;
        flex-grow: 1;

        &.card{
          justify-content: stretch;
          align-items: stretch;
          flex-direction: row;

          @media screen and (max-width: $bp_mobileLandscape ) {
            flex-direction: column;
            .item-image {
              margin: -1em -1em 0 -1em!important;
              padding-bottom: calc(40% + 1em)!important;
              min-width: calc(100% - 2em)!important;
            }
          }

          .item-image{
            margin: -1em 0 -1em -1em;
            padding: 0;
            background: gray;
            display: flex;
            flex-grow: 1;
            position: relative;
            align-self: stretch;
            background-position: center center;
            background-size: cover;
            min-width: calc(40% + 1em);
          }
          .item-content{
            flex-grow: 1;
            padding: 1em;
          }
        }


        h2 {
          margin: 0 0 1em;
          font-weight: 2em;
        }
        &.small {
          font-size: 0.8em;
        }
  
        svg {
          color: $bgBody;
          margin: 0 0.2em 0 0;
        }
      }
    }
  }
}