@import "../../../variables.scss";

#root {
  .progress-container {
    // border-radius: 8px;
    // border: 2px solid black;
    // overflow-x: hidden;
    // overflow-y: auto;
    display: flex;
    flex-direction: column;
    // align-content: flex-start;
    // justify-content: flex-start;
    align-items: stretch;

    flex-wrap: nowrap;
    // padding: 1em;

    .accorion-item {
      // width: 80%;
      margin-bottom: 0 !important;
      margin-top: 0 !important;

      #accordion-title {
        background-color: rgba(170, 177, 174, 0.432);
        display: flex;
      }
      #accordion-title > div {
        justify-content: space-between;
      }
    }

    .accorion-details {
      display: flex;
      flex-direction: column;

      .line {
        display: flex;
        flex-direction: row;

        justify-content: space-between;
        padding: 0.3 1em 0.3em 1em;
        margin: 0;
        flex-wrap: wrap;

        &:nth-child(even){
          background-color: rgba(0,0,0,0.1);
        }

        .score-container{
          width: 6%;
          @media screen and (max-width: $bp_mobile) {
            width: 100%;            
          }
        }
      }
    }
  }
}