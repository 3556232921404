@import "../../../variables.scss";

.add-students-root-container {
  flex-direction: column;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 65%;
  width: 80% !important;
  min-height: 85vh;
  max-height: 90vh;
  background-color: rgb(255, 255, 255);
  border-radius: 8px;
  overflow: hidden;
  align-items: stretch;
  padding: 0 2em;

  .header-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 0.8em;
    color: $primaryLight;
    padding: 10px;
    .buttons-container {
      position: absolute;
      right: 3em;
      padding: 0;
      justify-content: flex-end;
    }
    .header {
      font-weight: bold !important;
      font-size: 2em !important;
      margin-bottom: 7px !important;
    }
  }

  .add-student-rows {
    // display: flex;
    // flex-direction: column;

    // overflow-y: auto;
    // height: 450px;
    // min-height: 50vh;
    // align-content: flex-start;
    max-height: 56vh;

    .add-student-row {
      position: relative;
      display: flex;
      width: 100%;
      justify-content: stretch;
      align-items: center;
      padding: 1em 0;
      margin: 0;
      border-bottom: 0.2em dotted rgba(0, 0, 0, 0.1);
      &:last-child {
        border-bottom: rgba(0, 0, 0, 0);
      }

      &.server-side-error {
        padding-bottom: 1em;

        &::after {
          position: absolute;
          content: attr(data-server-side-error);
          display: inline-flex;
          width: 100%;
          height: 0.8em;
          font-size: 0.8em;
          color: $logoColor;
          left: 0;
          bottom: 0;
        }
      }

      .indicator {
        // height: 57px;
        cursor: default;
        flex-grow: 1;
        max-width: 2em;
        min-width: 2em;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0.3em;
        font-size: large;

        &.valid {
          // color: #89ad24c0;
          color: green;
        }
        &.invalid {
          color: red;
        }
        &.server-side-error {
          color: rgb(255, 136, 0);
        }
      }

      .student-field {
        margin-right: 1em;
        flex-grow: 1;
        fieldset {
          // border-radius: 0;
          // border-top-color: transparent;
          // border-left-color: transparent;
          // border-right-color: transparent;
        }
        p {
          font-size: 0.7em;
          line-height: 1em;
          position: absolute;
          bottom: -1.6em;
        }
      }
    }
  }

  .assign-program-container {
    // width: 80%;
    display: flex;
    padding: 8px;
    margin: 0 5px 0 5px;
    align-items: center;
    justify-content: flex-start;
    // border: 1px solid black;
    background-color: $primaryLighter2;
    border-radius: 8px;
    font-size: 0.7em;
    .title {
      font-size: 1.2em;
      color: black;
      margin: 0.2em;
    }
    .description {
      color: rgba(0, 0, 0, 0.8);
      font-size: 0.9em;
    }

    .program-select {
      display: flex;
      max-width: 400px;
      min-width: 200px;
      margin: 0 0.4em;
      &::before {
        border-radius: $borderRadius;
      }

      > div {
        background: white;
        padding: 0.4em;
        border-radius: $borderRadius;
      }
    }
  }

  .buttons-container {
    padding: 10px;
    display: flex;
    justify-content: center;
  }
}
