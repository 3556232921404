#root {
  .user-details {
    h1 {
      display: flex;
      flex-direction: row;
    }
    margin-bottom: 1em;
  }

  ul.pages-progress-display {
    display: flex;
    list-style: none;
    align-items: center;
    > li {
      width: 0.5em;
      height: 0.5em;
      background: gray;
      margin: 0.1em;
      &.done {
        background: green;
      }
      &.closed {
        background: rgba(0, 0, 0, 0.3);
      }

      &.text {
        width: inherit;
        height: inherit;
        background: none;
        font-size: 0.8em;
        margin: 0.2em;
      }
    }
  }

  .group-student-course {
    .course-progress {
      font-size: 1.4em;
      margin-right: 0.5em;
      margin-bottom: 0.5em;
      display: inline-flex;
      flex-direction: row;
      align-items: center;
    }
  }
}
