.help-tip {
  .help-tip-content {
    img {
      max-width: 100%;
    }
    a {
      color: #89d2ff;
    }
  }
}

.help-tip-button {
  margin: 0 !important;
  padding: 0 !important;
  min-width: inherit !important;
  svg {
    color: rgb(67, 110, 253);
  }
}
