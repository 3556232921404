$bp_mobile: 320px;
$bp_mobileLandscape: 480px;
$bp_tablet: 768px;
$bp_tabletLandscape: 1024px;
$bp_desktop: 1200px;
$bp_desktopLarge: 1500px;
$bp_desktopWide: 1920px;

$headerHeight: 40px;
$footerHeight: 40px;
$sidebarWidth: 240px;

$mainTopMargin: 20px;

$pagePaddingX: 2em;

$bgLight: #f0f0ec;
$bgBody: #d0cda1;

$questionColor: #5472fc; // #dd55ff;

$primary: #636035;
$primaryLight: #918d60;
$primaryLighter: #9c9a84;
$primaryLighter2: #e6e2b8;
$primaryDark: #37360d;

$gold: #d0b93f;

$logoColor: #FF0048;
$logoColor50p: #ff004881;

$warningColor: #ff7c00;
$errorColor: #ff001d;
$successColor: rgb(2, 110, 7);

$modalBgDark: hwb(207deg 39% 0%);
$modalBgLight: hwb(207deg 89% 0%);

$borderRadius: 12px;

// semitransparent gray
@for $var from 1 to 9 {
  .bg-gray-#{$var}{
    background-color: rgba(0,0,0, $var/10 )
  }
  .border-gray-#{$var}{
    border-color: rgba(0,0,0,$var/10)
  }
  
}