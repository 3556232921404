@import "../../../variables.scss";
.edit-student {
  // .react-swipeable-view-container {

  // }
  .cancel-button-container {
    justify-content: flex-end;
    margin-left: auto;
    button{
      padding: 0 0 0 0;
      min-width: inherit;
      span{
        padding: 0 0 0 0;
      }
    }
  }
  .buttons-container {
    display: flex;
    justify-content: center;
    margin: 1em 0;
    color: $primary;

    &.space-between{
      justify-content: space-between;
    }

    .title {
      font-size: 1.2em;
    }
  }
  .user-details {
    display: flex;
    flex-direction: column;
    padding: 1em 0;

    .text-field {
      margin: 0 0 0.6em;
    }

    .image-container {
      
      display: flex;
      align-items: stretch;
      justify-content: stretch;
      .image-selector{
        cursor: pointer;
        padding: 0.2em;
        background-color: rgba(0,0,0,0.1);
        border-radius: 1em;
        
        img {
          width: 6em;
          height: auto;
          border-radius: 1em;
          box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
        }
      }
    }

    .activity-and-buttons-container {
      display: flex;
      flex-direction: row;
      flex-grow: 1;

      .modal-switch {
        display: flex;
        align-items: center;

        .status {
          display: flex;
          align-items: center;
          margin: 0 1em 0 0;
          color: $primary;
        }
      }
    }

    .react-file-reader {
      flex-grow: 1;
    }
  }
  .assigned-programs {
    .assign-program-dialog {
      // Make selection more prominent
      .MuiListItem-button.Mui-selected{
      background-color: rgba(0,0,0,0.3);
      // add bold style to inner span
        span { 
          font-weight: bold;
        }
      }
    }
  }
  .assigned-groups {
  }
}
