@import "../../../variables.scss";

body #root .main {
  padding-bottom: 0px;
}
.bubble .MuiDataGrid-columnHeaderTitleContainer
.MuiDataGrid-iconButtonContainer .MuiBadge-root
.MuiBadge-anchorOriginTopRightRectangle {  
  display: none!important;  
}  
#root {
  .user-list {
    margin-bottom: 3em;
    .buttons {
      display: flex;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 0.5em 0;
      .add-button {
        background: green;
        color: white;
      }
    }
  }
  .user-profile {
    //background-color: cadetblue;
    display: flex;
    // flex-wrap: wrap;
    flex-direction: column;
    //justify-content: center;
    // margin-top: 1.6em;
    margin-bottom: 3em;
    // max-height: 55vh;

    .details-container {
      border-radius: $borderRadius;     
      background-color: $bgLight;   
      margin: 1em 0;
      width: 100%;

      .details-text{
        
        .details-row {
          margin: 0 0 0.4em;
          border-bottom: 2px dotted rgba(0,0,0,0.1);
          span:first-child {
            width: 48%;
            display: inline-flex;
            font-weight: 400;
            font-size: 1em;
            justify-content: flex-start;
            padding: 0.2em 0;
            margin-right: 2%;
            color: $primary;
          }
        } 
      }
    }

    .image-container {
      //background-color: wheat;
      display: flex;      
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .image {
        height: 20vh;
        width: auto;
        //border: 5px solid #636035;        
        border-radius: $borderRadius;
        box-shadow: 2px 2px 4px rgba(0,0,0,0.4);
      }
    }

    .status-credentials-container {
      border-radius: 8px;
      border: 2px solid black;
      align-content: flex-start;
      max-height: 35vh;
      padding: 1em;
      display: flex;

      .status {
        padding: 1em;
        border-bottom: 2px solid black;
        display: flex;
        flex-direction: row;
        max-height: 10vh;
        align-items: center;
        justify-content: space-around;
      }

      .credentials {
        padding: 1em;
        max-height: 28vh;

        display: flex;
        flex-direction: column;

        .list-text {
          margin-top: 25px;
        }
      }
    }

    .progress-container {
      // border-radius: 8px;
      // border: 2px solid black;
      // overflow-x: hidden;
      // overflow-y: auto;
      display: flex;
      flex-direction: column;
      // align-content: flex-start;
      // justify-content: flex-start;
      align-items: stretch;

      flex-wrap: nowrap;
      // padding: 1em;

      .accorion-item {
        // width: 80%;
        margin-bottom: 0 !important;
        margin-top: 0 !important;

        #accordion-title {
          background-color: rgba(170, 177, 174, 0.432);
          display: flex;
        }
        #accordion-title > div {
          justify-content: space-between;
        }
      }

      .accorion-details {
        display: flex;
        flex-direction: column;

        .line {
          display: flex;
          flex-direction: row;

          justify-content: space-between;
          padding: 0.3 1em 0.3em 1em;
          margin: 0;

          &:nth-child(even){
            background-color: rgba(0,0,0,0.1);
          }
        }
      }
    }

    .buttons-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between !important;
      align-items: stretch;
      .setting-buttons{
        display: flex;
        flex-direction: column;
      }
      a, button{        
        margin: 0 0 1em;
      }
    }
  }



  // .user-profile {
  //   //background-color: cadetblue;
  //   display: flex;
  //   // flex-wrap: wrap;
  //   flex-direction: column;
  //   //justify-content: center;
  //   margin-top: 1.6em;
  //   max-height: 55vh;
  //   align-items: stretch;

  //   .details-container {
  //     border-radius: 8px;
  //     border: 2px solid black;
  //     align-content: space-around;
  //     display: flex;
  //     max-height: 35vh;
  //     padding: 1em;

  //     .details-text {
  //       margin-top: 25px;
  //     }
  //   }

  //   .image-container {
  //     //background-color: wheat;
  //     display: flex;
  //     max-height: 35vh;
  //     align-content: center;
  //     justify-content: center;

  //     .image {
  //       width: 80%;
  //       //border: 5px solid #636035;
  //       border: 2px solid black;
  //       border-radius: 8px;
  //     }
  //   }

  //   .status-credentials-container {
  //     border-radius: 8px;
  //     border: 2px solid black;
  //     align-content: flex-start;
  //     max-height: 35vh;
  //     padding: 1em;
  //     display: flex;

  //     .status {
  //       padding: 1em;
  //       border-bottom: 2px solid black;
  //       display: flex;
  //       flex-direction: row;
  //       max-height: 10vh;
  //       align-items: center;
  //       justify-content: space-around;
  //     }

  //     .credentials {
  //       padding: 1em;
  //       max-height: 28vh;

  //       display: flex;
  //       flex-direction: column;

  //       .list-text {
  //         margin-top: 25px;
  //       }
  //     }
  //   }

  //   .progress-container {
  //     border-radius: 8px;
  //     border: 2px solid black;
  //     overflow-x: hidden;
  //     overflow-y: auto;
  //     display: flex;
  //     flex-direction: column;
  //     align-content: flex-start;
  //     justify-content: flex-start;
  //     align-items: center;

  //     flex-wrap: nowrap;
  //     padding: 1em;

  //     .accorion-item {
  //       width: 80%;
  //       margin-bottom: 0 !important;
  //       margin-top: 0 !important;

  //       #accordion-title {
  //         background-color: rgba(170, 177, 174, 0.432);
  //         display: flex;
  //       }
  //       #accordion-title > div {
  //         justify-content: space-between;
  //       }
  //     }

  //     .accorion-details {
  //       display: flex;
  //       flex-direction: column;

  //       .line {
  //         display: flex;
  //         flex-direction: row;
  //         align-items: center;
  //         justify-content: space-between;
  //         padding: 0 1em 0 1em;

  //         .score-container {
  //           display: flex;
  //           justify-content: flex-end;
  //           position: relative;
  //           width: 15%;
  //           .grade-icon {
  //             color: #e7c981;
  //           }
  //           .progress-bar {
  //             width: 50%;
  //           }
  //         }
  //       }
  //     }
  //   }

  //   .buttons-container {
  //     display: flex;

  //     justify-content: flex-end;
  //     align-items: center;
  //   }
  // }
}

.users-root-container {
  flex-direction: column;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 65%;
  width: 75% !important;
  min-height: 85vh;
  background-color: rgb(204, 196, 196);
  border-radius: 8px;
  overflow: hidden;
  justify-content: space-around;

  .header-container {
    display: flex;
    justify-content: center;
    padding: 10px;

    .header {
      font-weight: bold !important;
      font-size: 2em !important;
      margin-bottom: 7px !important;
    }
  }

  .image-fields-container {
    display: flex;
    padding: 10px;

    .text-fields {
      display: flex;
      flex-direction: column;
      padding: 10px;
    }
  }

  .activity-and-buttons-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .modal-switch {
      display: flex;
      //background-color: violet;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
    }

    .buttons-container {
      padding: 10px;
      display: flex;
      justify-content: flex-end;
    }
  }

  .image-container {
    display: flex;
    justify-content: center;
    padding: 10px;
    align-items: center;
  }

  .edit-programs-container {
    background-color: rgb(236, 215, 236);
    display: flex;
    flex-direction: column;
    align-content: space-around;
    border: 3px solid #636035;
    border-radius: 7px;
    margin: 10px;
  }
}

ul.tooltip-list {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  list-style: none;
  padding: 0;
  font-size: 0.8em;
  li {
    list-style: none;
    padding: 0;
    margin: 0.2em;
    display: flex;
    flex-direction: row;
    align-items: center;
    span:first-child {
      width: 5em;
      margin-right: 2em;
      white-space: nowrap;
    }
    span:nth-child(2),
    span:nth-child(3) {
      width: 4em;
      white-space: nowrap;
    }
  }
}
