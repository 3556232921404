@import "../../../../variables.scss";

.reports-students-list {
  position: absolute;
  overflow-y: auto;
}
.course-select-list.select-list {
  // padding: 0;
  // .MuiList-root {
  //   all:revert;
  //   padding: 0;
  // }

  ul{
    font-size: 0.9em;
    max-height: 20em;
    overflow-y: auto;
    background: none;
    border: none;
    padding-left: 0;
    li.select-list-item{
      border-radius: 0;
      background: none!important;
      margin: 0.2em 0;

      > span {
        // make space for checkmark
        padding: 0.2em 0.2em 0.2em 2em;
        margin-left: -1em;
        color: #888!important;
        width: calc(100% - 1em); // fill width
        

        &.selected{
          background: #ccc!important;
          &::before{
            content: '✓';
            position: absolute;
            left: 0.5em;
          }
        }
      }

      &:hover{
        // background-color: #ccc!important;
        // color: black!important;

        > span {
          background: #ddd!important;
          color: black!important;
        }
      }
    }
  }
}
// span.not-selected-course {
//   padding-left: 23px
// };
#root {
  .student-list {
    // add 'Select all' label to select all checkbox
    // checkbox-text been set in component did mount
    .MuiDataGrid-columnHeaderWrapper [data-field="__check__"]::after {
      content: attr(checkbox-text);
      position: absolute;
      display: flex;
      font-size: 0.8em;
      white-space: pre-wrap;
      width: 100%;
      align-items: center;
      top: -1.6em;
      justify-content: center;
      font-weight: 600;
    }

    .buttons{
      a{
        margin: 0 0.4em;
        &:first-child{
          margin-left: 0;
        }
      }
    }
  }

  
}
.block {
  width: 100%;
  .filter-block {
    display: flex;
    justify-content: flex-end;
    align-items: stretch;

    // & > div,
    .MuiFormControlLabel-root {
      min-width: 8em;
      border: 1px solid rgba(0, 0, 0, 0.1);
      padding: 0.1em 1em;
      border-radius: 0.5em;
      background: rgba(0, 0, 0, 0.02);
      margin: 0.3em;
    }

    label > span,
    label > div {
      font-size: 0.8em;
    }
  }
}
#root .grade-report {
  .grade-report-header {
    display: flex;
    justify-content: space-between;
  }
  .export-button {
    display: flex;
    justify-content: flex-end;
  }
  h3 {
    font-size: 0.95em;
    margin: 0.5em 0;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    font-size: 0.8em;
    span {
      display: flex;
      align-items: center;
    }
    span.progress {
      background: $primaryLight;
      padding: 0.3em 0.6em;
      border-radius: 0.2em;
      color: white;
    }
  }
  .user-grades {
    background: rgba(255, 255, 255, 0.3);
    padding: 1em;
    margin: 2em 0;

    .program-title {
      margin: 0.2em 0;
      justify-content: flex-start;      
      display: flex;
      .title{
        margin-right: 0.8em;
        font-size: 1.2em;
        font-weight: 600;;
        color: $primaryLight
      }
      .final-grade{
        font-size: 1.2em;
        color: $primaryLight;
        font-weight: 600;
      }
    }
    .student-title {
      justify-content: space-between;
      display: flex;
    }

    .course-grades {
      padding: 0.2em;
      background: white;
      height: calc(100% - 0.4em);
      
      .gradeHeader{
        display: flex;
        flex-direction: row;
        padding: 0.3em;
        white-space: nowrap;
      }
      .course-title{
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        // width: 100%; // this isn't needed
      }
      .MuiDataGrid-root {
        //   .MuiDataGrid-main {
        border: none;
        .table-cell-text {
          font-size: 0.7em;
          flex-grow: 1;
          font-weight: bold;
          text-align: center;
        }
        .experiment-cell-text{
          font-size: 0.7em;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        .MuiDataGrid-row.Mui-even {
          // border-bottom: 1px solid black;
          background: rgba(0, 0, 0, 0.1);
        }
      }      
    }

    .personal-data {        
        display: flex;
        flex-direction: row;
        align-items: center;
        font-weight: 600;
        margin-bottom: 0.2em;
        span {
          display: flex;
          align-items: center;
          flex-direction: row;
          margin-right: 0.6em;
          svg {
            color: $primaryLight;
          }
        }
      }
  }
}
